import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeStands = () => {
  return (
    <Layout>
      <Seo
        title="Fahrradständer - BIKESAFE"
        description="Jeder Fahrradstand unseres Sortiments wird gemäß Ihren Anforderungen maßgeschneidert und bietet Ihnen die wettbewerbsfähigsten Preise auf dem Markt an."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
              Fahrradständer
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
              Jeder Fahrradstand unseres Sortiments wird gemäß Ihren Anforderungen maßgeschneidert und bietet Ihnen die wettbewerbsfähigsten Preise auf dem Markt an.
            </p>
            <p className="flow-text">
              Entdecken Sie hier unsere Angebote :</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Traditioneller Fahrradanlehnbügel Münster</h3>
              <p>Das klassische und beliebte Design ist perfekt für alle diejenigen, die auf der Suche nach minimalistischen Fahrradparkmöglichkeiten mit tollem Preis-Leistungs-Verhältnis und geringem Wartungsaufwand sind.</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Traditioneller Fahrradanlehnbügel Münster (Schwarz)</h3>
              <p>Das klassische und beliebte Design ist perfekt für alle diejenigen, die auf der Suche nach minimalistischen Fahrradparkmöglichkeiten mit tollem Preis-Leistungs-Verhältnis und geringem Wartungsaufwand sind.</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BikeStands;